(function() {
    var CustomFiles = $('.custom-file');

    CustomFiles.each(function() {
        var $input  = $(this).find('input');
        var $output = $(this).find('.custom-file-output');
        
        $input.change(function() {
            var files = this.files;
            var length = files.length;
            var content = '';

            for(var i=0;i<length;i++) {
                content += (i > 0 ? ', ':'')+'<span class="custom-file--file-name">'+files[i].name+'</span>'
            }

            $output.html(content)
        })
    })
})();
